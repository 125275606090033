import React, { Component } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react';
import { UncontrolledButtonDropdown } from 'reactstrap';
// import logo from '/assets/img/logo.png'
import sygnet from '../../assets/img/brand/sygnet.svg';
import logoLoyalty from '../../assets/img/brand/vndid-logo.png';
import local from '../../services/local';
import request from '../../services/request';
import helper from '../../services/helper';
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  async onLogoutClick() {
    let rs = await helper.confirm('Bạn chắc chắn muốn đăng xuất?');
    if (!rs) return;
    // await request.request('/api/user/logout');
    local.clear();
    // this.props.history.push(`/login`)
    window.location.href = '';
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className='d-lg-none' display='md' mobile />
        <AppNavbarBrand
          full={{
            src: 'assets/img/vtt_logo.png',
            height: 25,
            alt: 'Loyalty',
          }}
          href={'/#/dashboard'}
          minimized={{
            src: logoLoyalty,
            height: 20,
            alt: 'Loyalty',
          }}
        />
        {/* <AppSidebarToggler className="d-md-down-none" display="lg" /> */}

        <Nav className='ml-auto' navbar>
          <UncontrolledButtonDropdown className='pull-right' type='button'>
            <DropdownToggle caret>
              <p className='m-0'>Xin chào {this.props.userInfo.name}</p>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <Link to='/profile?page=43'>
                  <i className='fa fa-user'></i> Thông tin cá nhân
                </Link>
              </DropdownItem>
              <DropdownItem onClick={this.onLogoutClick.bind(this)}>
                <i className='fa fa-lock'></i> Đăng xuất
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

// export default DefaultHeader;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo };
};
export default connect(mapStateToProps)(DefaultHeader);
