import React from 'react';
import Loadable from 'react-loadable';

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./views/User/Profile'),
  loading: Loading,
});
const PageEditor = Loadable({
  loader: () => import('./views/PageManager/PageEditor'),
  loading: Loading,
});
const FormViewer = Loadable({
  loader: () => import('./views/Form/FormViewer'),
  loading: Loading,
});
const ListViewer = Loadable({
  loader: () => import('./views/List/ListViewer'),
  loading: Loading,
});
const VoucherApprove = Loadable({
  loader: () => import('./views/VoucherApprove'),
  loading: Loading,
});
const Checkout = Loadable({
  loader: () => import('./views/Checkout'),
  loading: Loading,
});
const PrintOrder = Loadable({
  loader: () => import('./views/PrintOrder'),
  loading: Loading,
});
const ReportVoucherSource = Loadable({
  loader: () => import('./views/Report/ReportVoucherSource'),
  loading: Loading,
});
const ReportVoucherCategoryProvince = Loadable({
  loader: () => import('./views/Report/ReportVoucherCategoryProvince'),
  loading: Loading,
});
const ReportCustomerEvoucher = Loadable({
  loader: () => import('./views/Report/ReportCustomerEvoucher'),
  loading: Loading,
});
const ReportCustomerProduct = Loadable({
  loader: () => import('./views/Report/ReportCustomerProduct'),
  loading: Loading,
});
const ReportComplain = Loadable({
  loader: () => import('./views/Report/ReportComplain'),
  loading: Loading,
});
const ReportEVoucher = Loadable({
  loader: () => import('./views/Report/ReportEVoucher'),
  loading: Loading,
});
const ReportGeneralMerchant = Loadable({
  loader: () => import('./views/Report/ReportGeneralMerchant'),
  loading: Loading,
});
const ReportDetailMerchant = Loadable({
  loader: () => import('./views/Report/ReportDetailMerchant'),
  loading: Loading,
});

const CCSearchPhone = Loadable({
  loader: () => import('./views/CC/SearchPhone'),
  loading: Loading,
});
const CCSearchVoucher = Loadable({
  loader: () => import('./views/CC/SearchVoucher'),
  loading: Loading,
});
const CreateProduct = Loadable({
  loader: () => import('./views/Product/CreateProduct'),
  loading: Loading,
});
const EndUserInfo = Loadable({
  loader: () => import('./views/User/EndUserInfo'),
  loading: Loading,
});
const GiveVouchers = Loadable({
  loader: () => import('./views/User/GiveVouchers'),
  loading: Loading,
});
const GiveProducts = Loadable({
  loader: () => import('./views/User/GiveProducts'),
  loading: Loading,
});
const PrintRequestGive = Loadable({
  loader: () => import('./views/PrintRequestGive/PrintRequestGive'),
  loading: Loading,
});
const PrintVerifyRequestGive = Loadable({
  loader: () => import('./views/PrintRequestGive/PrintVerifyRequestGive'),
  loading: Loading,
});
const InviteProcess = Loadable({
  loader: () => import('./views/CC/InviteProcess'),
  loading: Loading,
});
const ComplainProcess = Loadable({
  loader: () => import('./views/CC/ComplainProcess'),
  loading: Loading,
});
const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/pageEditor', name: 'Quản lý trang', component: PageEditor },
  { path: '/form', name: 'Trang', component: FormViewer },
  { path: '/list', name: 'Trang', component: ListViewer },
  { path: '/profile', name: 'Trang cá nhân', component: Profile },
  {
    path: '/voucherApprove',
    name: 'Duyệt điều kiện voucher',
    component: VoucherApprove,
  },
  { path: '/checkout', name: 'Duyệt điều kiện voucher', component: Checkout },
  {
    path: '/reportvouchersource',
    name: 'Báo cáo voucher từ nguồn',
    component: ReportVoucherSource,
  },
  {
    path: '/reportvouchercategoryprovince',
    name: 'Báo cáo voucher từ nguồn',
    component: ReportVoucherCategoryProvince,
  },
  {
    path: '/reportcustomerevoucher',
    name: 'Báo cáo Tổng hội viên/Hạng hội viên',
    component: ReportCustomerEvoucher,
  },
  {
    path: '/reportcustomerproduct',
    name: 'Báo cáo Tổng hội viên/Hạng hội viên',
    component: ReportCustomerProduct,
  },
  {
    path: '/reportcomplain',
    name: 'Báo cáo khiếu nại',
    component: ReportComplain,
  },
  {
    path: '/reportevoucher',
    name: 'Báo cáo evoucher theo tổng hội viên/hạng hội viên',
    component: ReportEVoucher,
  },
  {
    path: '/reportgeneralmerchant',
    name: 'Báo cáo Tổng quan Merchant trên toàn hệ thống',
    component: ReportGeneralMerchant,
  },
  {
    path: '/reportdetailmerchant',
    name: 'Báo cáo Merchant ',
    component: ReportDetailMerchant,
  },

  {
    path: '/searchphone',
    name: 'Tìm kiếm số điện thoại',
    component: CCSearchPhone,
  },
  {
    path: '/searchvoucher',
    name: 'Tìm kiếm voucher',
    component: CCSearchVoucher,
  },
  {
    path: '/createProduct',
    name: 'Tạo sửa sản phẩm',
    component: CreateProduct,
  },
  { path: '/printOrder', name: 'In đơn hàng', component: PrintOrder },
  { path: '/enduserinfo', name: 'Tìm kiếm user', component: EndUserInfo },
  {
    path: '/giveVouchers',
    name: 'Lấy voucher cho khách hàng',
    component: GiveVouchers,
  },
  {
    path: '/giveProducts',
    name: 'Lấy sản phẩm cho khách hàng',
    component: GiveProducts,
  },
  {
    path: '/printRequestGive',
    name: 'Lấy sản phẩm cho khách hàng',
    component: PrintRequestGive,
  },
  {
    path: '/printVerifyRequestGive',
    name: 'Lấy sản phẩm cho khách hàng',
    component: PrintVerifyRequestGive,
  },
  {
    path: '/inviteProcess',
    name: 'Chi tiết mời sử dụng',
    component: InviteProcess,
  },
  {
    path: '/complainProcess',
    name: 'Chi tiết khiếu nại',
    component: ComplainProcess,
  },
];

export default routes;
