import React, { Component } from 'react';
import _ from 'lodash';
// import TinyMCE from "react-tinymce";
import tinymce from 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';
import request from '../services/request';
import helper from '../services/helper';
import 'tinymce/icons/default';
// // A theme is also required
import 'tinymce/themes/silver';
// // Any plugins you want to use has to be imported
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/wordcount';
class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      initValue: props.value,
    };
  }
  handleEditorChange(e) {
    let val = e.target.getContent();
    this.setState({ value: val });
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }
  handleOnEditorChange(newValue, editor) {
    let val = newValue;
    console.log(val);
    this.setState({ value: val });
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }
  /* componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  } */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    return (
      <div>
        {/* <TinyMCE
          content={this.props.value}
          config={{
            readonly: this.props.disabled,
            plugins: "autolink link image lists print preview",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright",
          }}
          onChange={this.handleEditorChange.bind(this)}
        /> */}
        <Editor
          // apiKey="cswezi492a2ax6zazo549bldqfazwrgrslsurvl1caolgntp"
          initialValue={this.state.initValue}
          // init={{
          //   readonly: this.props.disabled,
          //   plugins: [
          //     "code advlist autolink lists link image charmap print preview anchor",
          //     "searchreplace visualblocks code fullscreen",
          //     "insertdatetime media table contextmenu paste imagetools wordcount",
          //   ],
          //   toolbar:
          //     "insertfile undo redo | styleselect fontsizeselect fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code | image",
          //   font_formats:
          //     "Montserrat; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
          //   fontsize_formats:
          //     "8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 24pt 36pt",
          //   extended_valid_elements: "span",
          //   content_style:
          //     "@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');",
          //   images_upload_handler: async function (blobInfo, success, failure) {
          //     let imageFile = new FormData();
          //     imageFile.append("files", blobInfo.blob());
          //     try {
          //       let rs = await request.upload(
          //         `/api/file/upload-file`,
          //         imageFile
          //       );
          //       success(rs.created[0].url);
          //     } catch (error) {
          //       return;
          //     }
          //   },
          // }}
          init={{
            selector: 'textarea',
            convert_urls: false,
            height: 400,
            menubar: true,
            plugins: [
              'code advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks advcode fullscreen',
              'insertdatetime media table contextmenu powerpaste tinymcespellchecker a11ychecker save',
            ],
            font_formats:
              'Montserrat; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            fontsize_formats:
              '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 18pt 24pt 36pt',
            toolbar:
              'save undo redo | insert | styleselect fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | advcode spellchecker code a11ycheck',
            content_css: [
              '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
              '//www.tiny.cloud/css/codepen.min.css',
            ],
            powerpaste_allow_local_images: true,
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
            spellchecker_language: 'en',
            spellchecker_dialog: true,
            images_upload_handler: async function (blobInfo, success, failure) {
              let imageFile = new FormData();
              imageFile.append('files', blobInfo.blob());
              try {
                let rs = await request.upload(
                  `/api/file/upload-file`,
                  imageFile
                );
                success(rs.created[0].url);
              } catch (error) {
                return;
              }
            },
            //Save button call back function
            save_onsavecallback: function () {
              var content = tinymce.activeEditor.getContent();
              console.log(content);
            },
          }}
          onChange={this.handleEditorChange.bind(this)}
          // onEditorChange={this.handleOnEditorChange.bind(this)}
        />
      </div>
    );
  }
}

export default RichText;
