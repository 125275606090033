/* eslint no-eval: 0 */
import React, { Component } from 'react';
import helper from '../services/helper';
import Base from './Base';

// const refBase = React.createRef();
class FormSchema extends Component {
  checkError() {
    for (let i = 0; i < this.props.schema.length; i++) {
      console.log(
        'check invalid',
        this.isInvalid(
          this.props.schema[i],
          this.props.data[this.props.schema[i].field]
        )
      );
      if (this.props.schema[i].widget === 'TableInput') {
        // console.log('FormSchema -> checkError -> this.props.schema[i].widget', this.props.schema[i].widget);
        console.log('refBase', this.refBase);
        // console.log("refBase.current.getError(): ", refBase.current.getError());
        if (this.refBase) {
          if (this.refBase.getError()) {
            return i;
          }
        }
      }
      if (
        this.isInvalid(
          this.props.schema[i],
          this.props.data[this.props.schema[i].field]
        )
      ) {
        return i;
      }
    }
    return -1;
  }
  onSubmit() {
    console.log('on submit');
    let error = this.checkError();
    console.log('error', error);
    if (error > 0)
      return helper.alert(
        `Dữ liệu chưa đúng, kiểm tra lại thông tin ${this.props.schema[error].name}`
      );
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }
  isInvalid(schema, value) {
    console.log({ schema, value }, 'isInvalid');
    if (
      schema.required &&
      (value === null || value === undefined || value === '')
    )
      return true;
    return false;
  }
  render() {
    console.log(this.props, 'SCHEMA_PROPS');
    if (!this.props.schema) return <p>Chưa định nghĩa schema</p>;
    //convert default value
    this.props.schema.map((s) => {
      if (this.props.data[s.field] === undefined && s.default)
        this.props.data[s.field] = s.default;
      return null;
    });
    return (
      <form
        autoComplete='off'
        onSubmit={(evt) => {
          evt.preventDefault();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }}
      >
        <div className='row'>
          {this.props.schema.map((comp, index) => {
            if (comp.hideExpression) {
              console.log(comp.hideExpression, 'hideExpression');
              let str = comp.hideExpression;
              for (var i in this.props.data) {
                str = helper.replaceAll(str, i, this.props.data[i]);
                console.log(str, 'str hideExpression');
              }
              try {
                if (window.eval(str)) return null;
              } catch (err) {}
            }
            if (comp.widget === 'TableInput') {
              return (
                <div className='col-md-12'>
                  <Base
                    ref={(ref) => (this.refBase = ref)}
                    key={index}
                    schema={comp}
                    onChange={(e) => {
                      let dt = Object.assign({}, this.props.data);
                      dt[comp.field] = e;
                      if (this.props.onChange) {
                        this.props.onChange(dt);
                      }
                    }}
                    data={this.props.data}
                    value={this.props.data[comp.field]}
                    itemId={this.props.itemId || null}
                    custom={this.props.custom || null}
                  />
                </div>
              );
            } else {
              return (
                <div className='col-md-6'>
                  <Base
                    ref={(ref) => (this.refBase = ref)}
                    key={index}
                    schema={comp}
                    onChange={(e) => {
                      let dt = Object.assign({}, this.props.data);
                      // console.log(dt, 'pppp');
                      dt[comp.field] = e;
                      if (this.props.onChange) {
                        this.props.onChange(dt);
                      }
                    }}
                    data={this.props.data}
                    value={this.props.data[comp.field]}
                    itemId={this.props.itemId || null}
                    custom={this.props.custom || null}
                  />
                </div>
              );
            }
          })}
        </div>
        {/* {this.props.children} */}
        <div className='content-right'>{this.props.children}</div>
      </form>
    );
  }
}

export default FormSchema;
