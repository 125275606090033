import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactTable from "react-table";
import { Button, InputGroup, InputGroupAddon, ButtonGroup } from "reactstrap";
import helper from "../services/helper";
import Checkbox from "./Checkbox";
import _ from "lodash";
class ArrayModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      data: [],
      names: [],
      loading: true,
      search: "",
      pageId: props.schema.pageId,
      schema: props.schema,
      count: 0,
      columns: this.calculateColumns(props.schema),
      output: _.clone(props.value || []),
      nPages: 0,
      display: null,
      mode: "select",
    };
    this.init(props.schema.pageId, props.schema, _.clone(props.value || []));
  }
  itemsPerPage = 10;
  pageInfo = null;
  componentWillReceiveProps(next) {
    if (!next.value) {
      return this.setState({ names: [], output: [] });
    }
    if (!_.isEqual(this.state.output, next.value)) {
      this.fetchItemName(this.pageInfo, this.state.schema, next.value);
    }
    this.setState({ output: _.clone(next.value) });
  }
  async init(pageId, schema, output) {
    this.pageInfo = await helper.getPage(pageId);
    this.fetchItemName(this.pageInfo, schema, output);
  }

  toggle(mode) {
    this.setState({
      mode,
      modal: !this.state.modal,
    });
  }
  async fetchData(tbl) {
    let filter = {},
      sort = null;
    if (tbl.filtered) {
      tbl.filtered.map((f) => {
        switch (f.id) {
          case "id":
            filter.id = Number(f.value) || 0;
            break;
          default:
            filter[f.id] = { contains: f.value };
            break;
        }
        return null;
      });
    }
    if (tbl && tbl.sorted) {
      sort = [];
      tbl.sorted.map((s) => {
        sort.push({
          [s.id]: s.desc ? "desc" : "asc",
        });
        return null;
      });
    }
    if (sort.length === 0) sort = [{ id: "desc" }];
    if (this.state.mode === "view") {
      if (filter.id) {
        if (!_.includes(this.state.output, filter.id)) {
          filter.id = 0;
        }
      } else {
        filter.id = this.state.output;
      }
    }
    let modelSelectField = this.state.schema.modelSelectField;
    let arrSelectedField = modelSelectField.split(",");
    let selectedField = [];
    arrSelectedField.map((n) => {
      let arr = n.split("$$");
      selectedField.push(arr[0]);
    });
    try {
      let rs = await helper.callPageApi(this.pageInfo, this.state.schema.api, {
        select: selectedField.join(","),
        sort,
        queryInput: JSON.stringify(filter),
        limit: tbl.pageSize,
        skip: tbl.pageSize * tbl.page,
      });
      rs.data = this.calculateCheck(rs.data, this.state.output);
      this.setState({
        data: rs.data,
        count: rs.count,
        loading: false,
        nPage: Math.ceil(rs.count / tbl.pageSize),
      });
    } catch (err) {
      console.error(err);
    }
  }
  async fetchItemName(pageInfo, schema, output) {
    if (!pageInfo || !schema || !output) return;
    let filter = {};
    filter.id = output;
    try {
      let rs = await helper.callPageApi(pageInfo, schema.api, {
        queryInput: JSON.stringify(filter),
        select: "name",
      });
      let display = [];
      rs.data.map((d) => {
        return display.push(d.name);
      });
      this.setState({ names: rs.data, display: _.join(display, "-") });
    } catch (err) {
      console.error(err);
    }
  }
  calculateCheck(data, output) {
    data.map((d) => {
      if (_.includes(output, d.id)) return (d.checked = true);
      return (d.checked = false);
    });
    return data;
  }

  onCheckboxChanged(row, e) {
    let output = this.state.output.splice(0);
    if (e) {
      if (!_.includes(output, row.row.id)) output.push(row.row.id);
    } else {
      let tmp = [];
      output.map((o) => {
        if (o !== row.row.id) {
          tmp.push(o);
        }
        return null;
      });
      output = tmp;
    }

    let data = this.calculateCheck(this.state.data, output);
    this.setState({ data, output });
  }
  calculateColumns(schema) {
    let cols = [];
    let names = schema.modelSelectField.split(",");
    names.map((n) => {
      let arr = n.split("$$");
      cols.push({
        Header: arr[1] || n,
        accessor: arr[0],
      });
      return null;
    });
    cols.push({
      Header: "Chọn",
      accessor: "checked",
      filterable: false,
      Cell: (row) => {
        let val = false;
        for (var i = 0; i < this.state.data.length; i++) {
          if (this.state.data[i].id === row.row.id) {
            val = this.state.data[i].checked || false;
          }
        }
        return (
          <div>
            {this.state.mode === "select" ? (
              <Checkbox
                value={val}
                onChange={(e) => {
                  this.onCheckboxChanged(row, e);
                }}
              />
            ) : null}
          </div>
        );
      },
    });
    return cols;
  }
  confirm() {
    this.onChange(this.state.output);
    this.fetchItemName(this.pageInfo, this.state.schema, this.state.output);
    this.toggle();
  }
  onChange(dt) {
    console.log("dt", dt);
    if (this.props.onChange) {
      this.props.onChange(dt);
    }
  }
  onRemoveClick(id) {
    let output = [],
      names = [];
    for (var i = 0; i < this.state.names.length; i++) {
      if (this.state.names[i].id !== id) {
        output.push(this.state.names[i].id);
        names.push(this.state.names[i]);
      }
    }
    this.setState({ output, names });
    this.onChange(output);
  }
  renderNames() {
    if (this.state.names.length === 0) return <span></span>; //<Button color='default' type='button' size='sm'>Không chọn</Button>;
    if (this.state.names.length === 1) {
      return (
        <React.Fragment>
          {this.state.names.map((item, index) => {
            return item ? (
              <ButtonGroup size="sm" key={index} className="mr-2 select-item">
                <Button
                  color="danger"
                  type="button"
                  size="sm"
                  onClick={() => {
                    this.onRemoveClick(item.id);
                  }}
                >
                  <i className="fa fa-remove" />
                </Button>
                <Button color="default" type="button" size="sm">
                  {item.name}
                </Button>
              </ButtonGroup>
            ) : null;
          })}
        </React.Fragment>
      );
    }
    return (
      <h3>
        <span className="badge badge-info">{this.state.names.length}</span>
      </h3>
    );
  }
  render() {
    return (
      <div>
        <InputGroup>
          <div className="form-control select-container">
            {this.renderNames()}
          </div>
          <InputGroupAddon addonType="append">
            <Button
              disabled={this.props.disabled}
              type="button"
              color="primary"
              onClick={() => {
                this.toggle("select");
              }}
            >
              <i className="fa fa-plus" />
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <Modal isOpen={this.state.modal} fade={false} size={"lg"}>
          <ModalHeader>Chọn</ModalHeader>
          <ModalBody>
            <ReactTable
              previousText={"Trang trước"}
              nextText={"Trang sau"}
              pageText={"Trang"}
              rowsText={"bản ghi"}
              ofText={"trên tổng số"}
              data={this.state.data}
              loading={this.state.loading}
              manual
              filterable
              onFetchData={this.fetchData.bind(this)}
              pages={this.state.nPage}
              columns={this.state.columns}
              defaultPageSize={this.itemsPerPage}
              className="-striped -highlight"
            />
          </ModalBody>
          <ModalFooter>
            {this.state.mode === "select" ? (
              <React.Fragment>
                <Button color="primary mr-1" onClick={this.confirm.bind(this)}>
                  Xác nhận
                </Button>
                <Button color="secondary" onClick={this.toggle.bind(this)}>
                  Hủy bỏ
                </Button>
              </React.Fragment>
            ) : (
              <Button color="secondary" onClick={this.toggle.bind(this)}>
                Đóng
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ArrayModel;
