import React, { Component } from "react";
import helper from "../services/helper";
import _ from "lodash";
import ProductAttribute, { MAX_ATTRIBUTE } from '../components/Product/ProductAttribute';

class TableInput extends Component {
  constructor(props) {
    super(props);
    let data = {
      attrs: [],
      productDetail: [],
    };
    if (props.value && props.value.productDetail.length > 0) {
      const cpDetails = _.cloneDeep(props.value.productDetail);
      const attrs = props.value.attrs;
      data.attrs = attrs;
      if (attrs && attrs.length === MAX_ATTRIBUTE) {
        let idx = 0, idxx = 0;
        for (let x of attrs[0].attributeValues) {
          let idxy = 0;
          for (let y of attrs[1].attributeValues) {
            const prodDetails = cpDetails.filter(i => {
              if (i.pAttrValue) {
                const eCpx = {
                  [attrs[0].name]: x.name,
                  [attrs[1].name]: y.name
                };
                const cpx = _.omit(i.pAttrValue, ['isNew', 'key', 'id']);
                return _.isEqual(cpx, eCpx);
              }
              return false;
            });
            if (prodDetails.length > 0) {
              data.productDetail.push(prodDetails[0]);
            }
            idx += 1;
            idxy += 1;
          }
          idxx += 1;
        }
      } else if (attrs && attrs.length === MAX_ATTRIBUTE - 1) {
        let idx = 0;
        for (let x of attrs[0].attributeValues) {
          const prodDetails = cpDetails.filter(i => {
            if (i.pAttrValue) {
              const eCpx = {
                [attrs[0].name]: x.name
              };
              const cpx = _.omit(i.pAttrValue, ['isNew', 'key', 'id']);
              return _.isEqual(cpx, eCpx);
            }
            return false;
          });
          if (prodDetails.length > 0) {
            data.productDetail.push(prodDetails[0]);
          }
          idx += 1;
        }
      }
    } else {
      data = props.value;
    }


    // trường hợp edit sản phẩm
    let attributesValues = [];
    if (
      props.itemId &&
      props.itemId !== null &&
      props.value
    ) {
      // let dataProdDetails = _.clone(props.value.productDetail);
      let attributes = _.clone(props.value.attrs);
      if (attributes && attributes.length === MAX_ATTRIBUTE) {
        let idx = 0, idxx = 0;
        for (let x of attributes[0].attributeValues) {
          let idxy = 0;
          for (let y of attributes[1].attributeValues) {
            const prodDetails = props.value.productDetail.filter(i => {
              if (i.pAttrValue) {
                const eCpx = {
                  [attributes[0].name]: x.name,
                  [attributes[1].name]: y.name
                };
                const cpx = _.omit(i.pAttrValue, ['isNew', 'key', 'id']);
                return _.isEqual(cpx, eCpx);
              }
              return false;
            });

            attributesValues.push({
              id: `${idxx}-${idxy}`,
              key: idx,
              attrIds: [
                { id: attributes[0].attrId, name: attributes[0].name },
                { id: attributes[1].attrId, name: attributes[1].name }
              ],
              [attributes[0].name]: x.name,
              [attributes[1].name]: y.name,
              product: prodDetails.length > 0 ? prodDetails[0] : {}
            });
            idx += 1;
            idxy += 1;
          }
          idxx += 1;
        }
      } else if (attributes && attributes.length === MAX_ATTRIBUTE - 1) {
        let idx = 0;
        for (let x of attributes[0].attributeValues) {
          const prodDetails = props.value.productDetail.filter(i => {
            if (i.pAttrValue) {
              const eCpx = {
                [attributes[0].name]: x.name
              };
              const cpx = _.omit(i.pAttrValue, ['isNew', 'key', 'id']);
              return _.isEqual(cpx, eCpx);
            }
            return false;
          });
          attributesValues.push({
            id: `0-${idx}`,
            key: idx,
            attrIds: [
              { id: attributes[0].attrId, name: attributes[0].name }
            ],
            [attributes[0].name]: x.name,
            product: prodDetails.length > 0 ? prodDetails[0] : {}
          });
          idx += 1;
        }
      }

      /* const merged = _.merge(
        _.keyBy(details, "key"),
        _.keyBy(otherDetails, "key")
      );
      console.log("render -> merged", merged);
      details = _.values(merged);
      console.log('2.render -> details', details); */
    }

    this.state = {
      data: data || {
        attrs: [],
        productDetail: [],
      },
      pageId: props.schema.pageId,
      schema: props.schema,
      // modal: false,
      // schemaDetail,
      dataDetail: {},
      attributesValues: attributesValues,
      crudProductIndex: null,
      error: false,
      listSelectAttrs: []
    };
    this.init(props.schema.pageId, props);

  }

  itemsPerPage = 5;
  pageInfo = null;
  schema = null;

  componentWillReceiveProps(next) {
    if (this.props.dataSchema && this.props.dataSchema !== next.dataSchema
      && this.props.dataSchema.categoryId !== next.dataSchema.categoryId
    ) {
      this.fetchAttributes(next.dataSchema.categoryId);
    }
  }

  async init(pageId, props) {
    console.log("TableInput -> init -> pageId %o \n props %o", pageId, props);
    this.pageInfo = await helper.getPage(pageId);
    this.schema = props.schema;
    await this.fetchAttributes();
  }

  async fetchAttributes(categoryId) {
    // console.log('TableInput -> fetchAttributes -> this.props.dataSchema', this.props.dataSchema);
    let rs;
    if (categoryId) {
      rs = await helper.callPageApi(this.pageInfo, 'findAttributes', { categoryId: categoryId });
    } else if (this.props.dataSchema.categoryId) {
      rs = await helper.callPageApi(this.pageInfo, 'findAttributes', { categoryId: this.props.dataSchema.categoryId });
    } else {
      // rs = await helper.callPageApi(this.pageInfo, 'findAttributes', { });
    }
    /* for (let itemAttribute of rs) {
      const colAttribute = `${itemAttribute.name}-${itemAttribute.id}`;
      attributesValues = _.assign(attributesValues, { [colAttribute]: itemAttribute.attributesValues });

    } */
    this.setState({ listSelectAttrs: (rs && rs.data) || [] });
  }

  onChange = (data) => {
    if (this.props.onChange) {
      let cpData = _.cloneDeep(data);
      cpData = {
        ...cpData,
        productDetail: (cpData.productDetail || []).map(item => {
          const cpItem = _.pick(item, ['product']);
          const pAttrValue = _.omit(item, ['product']);
          return {
            ...(cpItem.product || {}),
            pAttrValue
          };
        })
      };
      this.props.onChange(cpData);
    }
  };

  setError = (error) => {
    this.setState({ error });
  };

  getError = () => {
    return this.state.error;
  };

  render() {
    // console.log('TableInput -> render -> this.props.data', this.props.dataSchema);
    if (this.schema && !this.schema.modelSelectField)
      return <p>Thiếu dữ liệu modelSelectField</p>;

    return (
      <div>
        <ProductAttribute
          dataProduct={this.state.data.productDetail}
          attributes={this.state.data.attrs}
          attributesValues={this.state.attributesValues}
          itemId={this.props.itemId || null}
          onChange={this.onChange}
          setError={this.setError}
          listSelectAttrs={this.state.listSelectAttrs}
        />
      </div>
    );
  }
}

export default TableInput;
