import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
// import navigation from '../../_nav';
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import local from "../../services/local";
import request from "../../services/request";
import _ from "lodash";
class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      nav: [],
      userInfo: {},
    };
  }
  nav = [];
  async componentDidMount() {
    try {
      if (!local.get("session")) {
        this.props.history.replace("/login");
      }
      //load meta data
      let meta = await request.request("/api/admin/get-meta");
      meta.pages.map((page) => {
        if (!Array.isArray(page.buttons)) page.buttons = [];
        // if (page.form && page.form.schema && page.form.schema.properties) {
        //   for (var i in page.form.schema.properties) {
        //     page.form.schema.properties[i].pageId = page.id;
        //   }
        //   for (i in page.schema) {
        //     page.schema[i].pageId = page.id;
        //   }
        // }
        for (var i in page.schema) {
          page.schema[i].pageId = page.id;
        }
        return null;
      });
      local.set("meta", JSON.stringify(meta));
      let userInfo = local.get("user");
      let nav = this.calculateNav(meta.menus, userInfo.role);
      this.setState({ loading: false, nav });
    } catch (err) {
      this.props.history.replace("/login");
    }
  }
  calculateNav(allMenus, role) {
    let menus = [];
    allMenus.map((m) => {
      m.isOpen = false;
      if (!m.roles) {
        return menus.push(m);
      }
      if (m.roles.length === 0) {
        return menus.push(m);
      }
      if (_.includes(m.roles, role)) {
        return menus.push(m);
      }
      return null;
    });

    let items = [];
    menus.map((m) => {
      if (!m.parent) {
        m.isOpen = false;
        if (!m.url) m.url = "url";
        items.push(m);
      }
      return null;
    });
    menus.map((m) => {
      if (!m.parent) return null;
      for (var i = 0; i < items.length; i++) {
        if (items[i].id === m.parent) {
          if (!items[i].children) items[i].children = [];
          items[i].children.push(m);
        }
      }
      return null;
    });
    return { items };
  }
  render() {
    if (this.state.loading) return <p>Đang xử lý...</p>;
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav
              navConfig={this.state.nav}
              location={this.props.location}
            />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            <Container fluid className="mt-4">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/login" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        {/* <AppFooter>
          <DefaultFooter />
        </AppFooter> */}
      </div>
    );
  }
}

// export default DefaultLayout;
const mapStateToProps = (state) => {
  return { userInfo: state.userInfo };
};
export default connect(mapStateToProps)(DefaultLayout);
