/* global google */
import React, { Component } from "react";
import { Input, InputGroupAddon, InputGroup, Button } from "reactstrap";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import _ from "lodash";
import { compose, withProps, lifecycle } from "recompose";
const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <Marker
        position={{ lat: props.lat, lng: props.lng }}
        draggable={true}
        onDragEnd={(evt) => {
          if (props.onChange) {
            let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
            props.onChange(rs);
          }
        }}
      />
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Tìm kiếm địa chỉ"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `500px`,
            height: `32px`,
            marginTop: `8px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </SearchBox>
    </GoogleMap>
  ))
);

export const MapWithASearchBox = compose(
  lifecycle({
    componentWillMount() {
      const refs = {};
      let rs = [21.0575491, 105.7909262];
      this.setState({
        bounds: null,
        center: {
          lat: rs[0],
          lng: rs[1],
        },
        markers: [],
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place) => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map((place) => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(
            nextMarkers,
            "0.position",
            this.state.center
          );

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });
          refs.map.fitBounds(bounds);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={15}
    // center={props.center}
    onBoundsChanged={props.onBoundsChanged}
    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }}
  >
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Điền vị trí cần tìm"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          position: "absolute",
          left: "50%",
          marginLeft: "-120px",
        }}
      />
    </SearchBox>
    {props.markers.length > 0 ? (
      // props.markers.map((marker, index) => (
      //   <Marker
      //     key={index}
      //     position={marker.position}
      //     draggable={true}
      //     onDragEnd={(evt) => {
      //       if (props.onChange) {
      //         let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
      //         props.onChange(rs);
      //       }
      //     }}
      //     onClick={(evt) => {
      //       if (props.onChange) {
      //         let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
      //         props.onChange(rs);
      //       }
      //     }}
      //   />
      // ))
      <Marker
        position={props.markers[0].position}
        draggable={true}
        onDragEnd={(evt) => {
          if (props.onChange) {
            let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
            props.onChange(rs);
          }
        }}
        onClick={(evt) => {
          if (props.onChange) {
            let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
            props.onChange(rs);
          }
        }}
      />
    ) : (
      <Marker
        position={{ lat: props.lat, lng: props.lng }}
        draggable={true}
        onDragEnd={(evt) => {
          if (props.onChange) {
            let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
            props.onChange(rs);
          }
        }}
        onClick={(evt) => {
          if (props.onChange) {
            let rs = `${evt.latLng.lat()}/${evt.latLng.lng()}`;
            props.onChange(rs);
          }
        }}
      />
    )}
  </GoogleMap>
));
class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }
  render() {
    let rs = [21.0575491, 105.7909262];
    if (this.props.value) {
      rs = this.props.value.split("/");
      if (!isNaN(Number(rs[0])) && !isNaN(Number(rs[1]))) {
        rs[0] = Number(rs[0]);
        rs[1] = Number(rs[1]);
      }
      // markers.push(
      //   new google.maps.Marker({
      //     position: { lat: rs[0], lng: rs[1] },
      //   })
      // );
    }
    return (
      <div>
        <InputGroup>
          <Input
            type="text"
            id="name"
            placeholder="Nhập địa chỉ gps. Ví dụ: 21.0575491/105.7909262"
            required
            value={this.props.value}
            onChange={(evt) => {
              if (this.props.onChange) {
                this.props.onChange(evt.target.value);
              }
            }}
          />
          {/* <InputGroupAddon addonType="append">
            <Button type="button" color="default">
              Nhập vị trí
            </Button>
          </InputGroupAddon> */}
        </InputGroup>

        <div style={{ marginTop: "15px" }}>
          <MapWithASearchBox
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAwxODvXLC4zF3NBxJIXCguUGSd-1K5dWc&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={rs[0]}
            lng={rs[1]}
            onChange={(val) => {
              if (this.props.onChange) {
                this.props.onChange(val);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default Location;
