import React, { Component } from 'react';
import { Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
class NumberMask extends Component {
    render() {
        return <NumberFormat
            className={`form-control`}
            allowNegative={false}
            suffix={` vnđ`}
            value={this.props.value}
            thousandSeparator={true}
            onValueChange={(values) => {
                let { formattedValue, value } = values;
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            }}
        />
    }
}

export default NumberMask;