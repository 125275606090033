import Local from './local';
import Helper from './helper';
import helper from './helper';
let request = {};
request.upload = async (url, formData) => {
  url = `${window.host}${url}`;
  let option = {
    method: 'PUT', // or 'PUT'
    body: formData,
    headers: {
      Authorization: `Bearer ${Local.get('session') || 'customer'}`
    }
  };
  if (window.debug) console.log(`[POST]`, url, option);
  let res = await fetch(url, option);
  let rs = await res.json();
  if (res.status !== 200) {
    console.log(res);
    throw rs;
  }
  if (window.debug) console.log(`[RESPONSE]`, url, rs);
  return rs;
};
request.request = async (url, data, headers, method = 'POST') => {
  url = `${window.host}${url}`;
  let option = {
    method, // or 'PUT'
    body: JSON.stringify(data), // data can be `string` or {object}!
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${Local.get('session') || 'customer'}`
    }
  };
  option.headers = Object.assign({}, option.headers, headers);
  if (method === 'GET') delete option.body;

  if (window.debug) console.log(`[${method}]`, url, option);

  // helper.showLoading();
  let res = await fetch(url, option);
  // helper.hideLoading();
  try {
    let rs = await res.json();
    if (rs.access_token) {
      Local.set('session', rs.access_token);
    }
    if (window.debug) console.log(`[RESPONSE]`, url, rs);
    switch (res.status) {
      case 401:
        Helper.alert('Phiên làm việc hết hạn. Vui lòng đăng nhập lại');
        window.location.href = '/';
        break;
      case 200:
        return rs;
      default:
        throw rs;
    }
  } catch (err) {
    console.log('res', res, err);
    throw err;
  }
};
export default request;
